/* 
  Unfortunately we need a few !important's to override some of the 
  inline and overly specific selectors included as part of the rc-slider styles.
*/

.root .slider {
  padding: 16px 0;
  margin: calc(var(--spacer) / 4) 0;
}

.slider :global(.rc-slider-rail),
.slider :global(.rc-slider-track) {
  height: 4px;
  border-radius: 4px;
}

.slider :global(.rc-slider-rail) {
  background-color: var(--color-london-80);
}

.slider :global(.rc-slider-track) {
  background-color: var(--color-denim-45);
}

.slider :global(.rc-slider-handle) {
  height: 32px;
  width: 32px;
  background-color: var(--color-denim-45);
  border: 4px solid var(--color-white-100);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 1;
  transition: all 0.05s ease;
  transform: translateX(-50%) translateY(-50%) !important;
  margin-top: 0;
}

.slider :global(.rc-slider-handle:hover) {
  border-color: var(--color-white-100);
}

.slider :global(.rc-slider-handle:focus) {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.slider :global(.rc-slider-handle:active) {
  border-color: var(--color-denim-65);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.slider :global(.rc-slider-handle-dragging) {
  border-color: var(--color-white-100) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  height: 38px;
  width: 38px;
}

.labels {
  display: flex;
  justify-content: space-between;
  gap: var(--spacer);
}

.minMaxLabel {
  color: var(--color-white-45);
  font-size: 14px;
  font-weight: 700;
  transition: color 0.6s ease;
}

.flash {
  color: var(--color-denim-45);
}