:root {
  --spacer: 22px;
  --panelTransitionDuration: 0.6s;
}

.root {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  border-radius: 12px;
  overflow: hidden;
  color: var(--color-denim-15);
}

.controls {
  background-color: var(--color-white-100);
  flex: 0 0 47%;
}

.form {
  margin: 0;
}

.accordion {
  border-bottom: 1px solid var(--color-white-85);
  padding: var(--spacer);
}

.accordion:last-child {
  border-bottom: 0;
}

.accordionTrigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: calc(var(--spacer) / 2);
}

.accordionTrigger[disabled] {
  color: var(--color-white-45);
}

.accordionHeading {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  transition: all 0.4s ease;
}

.accordionTooltip {
  display: flex;
}

.accordionContentInner {
  padding-top: calc(var(--spacer) / 3);
}

.salesInputWrapper,
.costInputWrapper,
.percentageInputWrapper {
  position: relative;
}

.salesInputWrapper input,
.costInputWrapper input {
  padding-left: 27px;
}

.costInputWrapper input,
.percentageInputWrapper input {
  background-color: var(--color-london-95);
  border: 1px solid var(--color-london-95);
}

.salesInputWrapper::before,
.costInputWrapper::before,
.percentageInputWrapper::before {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  font-weight: 700;
}

.salesInputWrapper::before,
.costInputWrapper::before {
  content: "£";
  left: calc(var(--spacer) / 2);
  transition: color 0.4s ease;
}

.percentageInputWrapper::before {
  content: "%";
  left: 26px;
  transition: color 0.4s ease;
}

.flash {
  color: var(--color-denim-45);
}

.percentageInputWrapperWide::before {
  left: 36px;
}

.output {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--color-denim-15);
  color: var(--color-white-100);
  overflow: hidden;
}

.mobileDivider {
  height: auto;
  width: 100%;
}

.outputSlider {
  display: flex;
  width: 200%;
  transition: all var(--panelTransitionDuration)
    cubic-bezier(0, 1.04, 0.53, 0.98);
}

.outputSliderActive {
  transform: translateX(-50%);
}

.outputSliderActive .benefitsPanel {
  visibility: hidden;
}

.outputSliderActive .costsPanel {
  visibility: visible;
}

.outputPanel {
  flex: 1;
  overflow: hidden;
  padding: var(--spacer);
  transition: var(--panelTransitionDuration);
}

.costsPanel {
  visibility: hidden;
}

.benefitsHeading {
  font-size: 18px;
  font-weight: 700;
  color: var(--color-white-100);
  text-align: center;
}

.outputList {
  margin: 0;
  list-style: none;
  background-color: var(--color-denim-10);
  border-radius: 12px;
  padding: var(--spacer) calc(var(--spacer) / 2);
}

.outputList li {
  display: flex;
  align-items: center;
  gap: calc(var(--spacer) / 2);
  margin: 0;
}

.outputList svg {
  flex-shrink: 0;
}

.outputList li:not(:last-child) {
  padding-bottom: var(--spacer);
  margin-bottom: var(--spacer);
  border-bottom: 1px solid #323f4e;
}

.outputListHeading {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 0;
}

.outputListDescription {
  font-size: 12px;
  font-weight: 300;
}

.outputListFigure {
  font-size: 18px;
  font-weight: 400;
  margin-left: auto;
  flex-shrink: 0;
}

.applyButton {
  margin: auto var(--spacer) var(--spacer);
}

.applyButton span {
  font-weight: 300;
}

@media screen and (min-width: 700px) {
  .root {
    flex-direction: row;
  }

  .accordion:last-child {
    border-bottom: 1px solid var(--color-white-85);
  }

  .mobileDivider {
    display: none;
  }
}
