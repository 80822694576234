.trigger {
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  display: flex;
  cursor: pointer;
}

.popper {
  background-color: var(--color-white-100);
  color: var(--color-denim-15);
  border-radius: 12px;
  border: 1px solid var(--color-white-85);
  font-weight: 300;
  max-width: 230px;
  text-align: center;
  padding: calc(var(--spacer) / 2);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.popper[aria-hidden="false"] {
  opacity: 1;
  visibility: visible;
}

.popper[data-popper-placement="top"] .arrow {
  bottom: -7px;
}

.popper[data-popper-placement="top"] .arrow::before {
  transform: rotate(45deg);
}

.popper[data-popper-placement="bottom"] .arrow {
  top: -7px;
}

.popper[data-popper-placement="bottom"] .arrow::before {
  transform: rotate(225deg);
}

.popper[data-popper-placement="left"] .arrow {
  right: -7px;
}

.popper[data-popper-placement="left"] .arrow::before {
  transform: rotate(315deg);
}

.popper[data-popper-placement="right"] .arrow {
  left: -7px;
}

.popper[data-popper-placement="right"] .arrow::before {
  transform: rotate(135deg);
}

.arrow::before {
  background-color: var(--color-white-100);
  content: "";
  height: 13px;
  width: 13px;
  display: block;
  border-bottom: 1px solid var(--color-white-85);
  border-right: 1px solid var(--color-white-85);
}
