/* Fonts */
@font-face {
  font-family: iwocaFont;
  src: local("Aesop Bold") local("Aesop-Bold");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: iwocaFont;
  src: local("Aesop Light"), local("Aesop-Light");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: iwocaFont;
  src: local("Aesop Medium"), local("Aesop-Medium");
  font-weight: 400;
  font-style: normal;
}

body {
  background-color: #000000;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  color: white;
  padding: 50px;
}

.inner {
  max-width: 100%;
}
