.root {
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  height: 0;
}

.isActive {
  opacity: 1;
  visibility: visible;
  height: auto;
}
