.root {
  background-color: var(--color-coral-65);
  color: var(--color-white-100);
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding: calc(var(--spacer) / 2) var(--spacer);
  box-shadow: 0 15px 25px rgb(0 0 0 / 20%);
  transition: all 0.2s ease;
  cursor: pointer;
  text-decoration: none;
}

.root:hover {
  transform: translateY(3px);
  box-shadow: 0 4px 4px rgb(0 0 0 / 35%);
}
