.root {
  background-color: var(--color-white-100);
  color: var(--color-denim-15);
  border: 1px solid var(--color-white-85);
  border-radius: 12px;
  padding: calc(var(--spacer) / 3) calc(var(--spacer) / 2);
  width: 100%;
  font-weight: 700;
  transition: all 0.6s ease;
}

.root:focus {
  /*box-shadow: 0 0 0 1px var(--color-white-100), 0 0 0 3px var(--color-denim-65);*/
  outline: none;
}

.flash {
  color: var(--color-denim-45);
  border: 1px solid var(--color-denim-45) !important;
}

